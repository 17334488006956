import AgencyPage from "./landings/_agencyPage";
import {graphql} from "gatsby";

export default AgencyPage

export const frontmatter = {
  pageMetaData: {
    refKey: "agency",
    title: "Digital Agency' preferred Backup Solution",
    description: "Automate backups for all your projects and customers. Server, database, storage, snapshot and application backup, automated and stored on any cloud provider.",
  }
}

export const pageQuery = graphql`
  query CaseStudiesListAgencyQuery {
    posts: allMdx(
      filter: {frontmatter: {layout: {eq: "casestudy"}}},
      sort: {fields: frontmatter___date, order: ASC},
      skip: 1,
      limit: 2,
      ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            userQuote
            userRole
            userName
            userPhoto
            companyShortDescription
            date(formatString: "DD-MM-YYYY")
          }
        }
      }
    }
  }
`