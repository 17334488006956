import React, {useRef} from "react"
import {graphql} from "gatsby";
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import { Link, SignupButton, Row, Typography,  Container, Section, Faqs, SectionTitle, Testimonial, FeatureRows, Quote} from "components/ui"
import Image from "components/image"
import VideoModal from './_videoModal';
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const AgencyPage = ({data}) => {
  const videoRef = useRef(null);

  return (
  <Layout.Layout textColor="dark">
    <Layout.SEO refKey="agency" />
    <Layout.Hero
      type="primary"
      title={(<>Orchestrate all your <span className="text-primary">agency</span> projects' backups.</>)}
      subTitle={(
        <>Secure & automated backups for busy agencies building the web.<br/>No maintenance, secure, quick setup - best in class.
        </>)}
      checkMarks={['Project management', 'Team & customer access', 'Bring your own storage']}
      icons={[
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your agency' backups" urlAtrr={{ sb_source: "website", sb_term: "agency-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-hero-dashboard.png" alt="SimpleBackups MySQL backup dashboard: all your MySQL backups in one place"/>
      )}
    />

    <Ui.Container>
      <div className="md:mb-24">
        <Sections.JoinBrandsSection />
      </div>
    </Ui.Container>

    <Section>
      <Container>
        <Row>
          <div><Quote author="- Said by too many developers">I need help, ... the backup folder is empty, who configured it?</Quote></div>

          <div className="pt-16 md:mt-16">
            <Ui.Typography tag="h2" className="text-center">Trust your backups & build amazing things</Ui.Typography>
            <div className="grid md:grid-cols-3 gap-16 text-left mt-16">
              <div>
                <i className="fad fa-face-sleeping text-primary text-4xl" />
                <h3 className="text-xl font-bold mt-4 mb-1">Trust your backups</h3>
                <p className="text-base">We take care of your backups, period. Work on what matters, we'll let you know if something needs your attention.</p>
              </div>

              <div>
                <i className="fad fa-hourglass-end text-primary text-4xl" />
                <h3 className="text-xl font-bold mt-4 mb-1">Save on development time</h3>
                <p className="text-base">Forget about Bash, and unreliable backup scripts. Schedule your backups, choose your preferred storage, and you’re good to go!</p>
              </div>

              <div>
                <i className="fad fa-couch text-primary text-4xl" />
                <h3 className="text-xl font-bold mt-4 mb-1">No maintenance required</h3>
                <p className="text-base">We make sure your backups run. Optimized backups, with notificaitons and reporting bring to you.</p>
              </div>

            </div>


          </div>

          <div className="md:w-2/3 flex items-center m-auto mt-12 cursor-pointer hover:shadow-2xl transition transform duration-100 rounded-2xl" onClick={() => videoRef.current.show()}>
            <Image className="rounded-2xl" filename="video-1"></Image>
          </div>
        </Row>
      </Container>
    </Section>

    <Section color="alt">
      <Container>
        <Row>
          <div className="mt-24">
            <FeatureRows items={
              [
                Content_FeatureControl({subject:'project'}),
                Content_FeatureTrust(),
              ]}
            />
          </div>
          <p className="mt-12 text-center"><Link color="primary" to="/security-first/" underline={false}><i className="fa fa-angle-right mr-2 text-sm"></i>How we make sure your backups are safe</Link></p>
        </Row>
      </Container>
    </Section>

    <Sections.SectionTestimonialExtended />

    <Sections.SectionFaq color="alt" faqs={['use_for_client', 'is_secure', 'gdpr_compliance', 'refund_policy']}/>

    <Sections.SectionGetStarted/>
    <VideoModal ref={videoRef} />
  </Layout.Layout>
)}

export default AgencyPage
